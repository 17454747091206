import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import LinkComponent from "components/Link";
import { useRouter } from "next/router";
import { css } from "@emotion/react";
import { getLastPathname } from "utils/routerPath";
import { AppContext } from "contexts/AppContext";

const NavLink = ({ href = "/", children, ...rest }) => {
  const router = useRouter();
  const { query } = router;
  const { pageSlug, subPage } = query;
  const { allParents } = useContext(AppContext);
  let navSlug = pageSlug;
  if (pageSlug?.split("-").length > 1) {
    const splitArr = pageSlug.split("-");
    const firstShift = splitArr.shift();
    if (allParents.includes(firstShift)) navSlug = firstShift;
  }
  if (subPage) {
    if (subPage.split("-").length > 1) {
      const splitArr = subPage.split("-");
      const firstShift = splitArr.shift();
      if (allParents.includes(firstShift)) navSlug = firstShift;
    } else {
      navSlug = subPage;
    }
  }
  const Link = styled(LinkComponent)`
    ${({ href, theme }) =>
      navSlug === getLastPathname(href) && href !== "/"
        ? css`
            color: ${theme.colors.grey[6]};
          `
        : ``};
  `;
  return (
    <Link href={href} {...rest}>
      {children}
    </Link>
  );
};
export default NavLink;
