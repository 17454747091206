import React from "react";
import styled from "@emotion/styled";
import SvgLogo from "components/Svg/SvgLogo";
import { Box, Button, Container, Text } from "theme-ui";
import LinkComponent from "components/Link";

const NavLogo = ({ currentSubNavLogo, ...rest }) => {
  return (
    <Link href="/">
      <LogoWrapper {...rest}>
        <SvgLogo />
        <StyledText ml={"10px"} variant="logoText">
          Marquette Properties
        </StyledText>
      </LogoWrapper>
    </Link>
  );
};
export default NavLogo;

const LogoWrapper = styled(Box)`
  display: flex;
  align-items: center;

  svg path {
    fill: ${({ theme }) => theme.colors.white[1]};
  }
`;

const Link = styled(LinkComponent)`
  color: ${({ theme }) => theme.colors.grey[6]};
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.text};
  width: 75px;
`;
