import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import ExploreMoreItems from "./ExploreMoreItems";
import NavLogo from "components/Global/Nav/NavLogo";
import { AppContext } from "contexts/AppContext";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { useRouter } from "next/router";

const rowsNoBorder = ["ImageRow", "GalleryRow"];
const mobileRowsNoBorder = [...rowsNoBorder, "AccordionRow", "StackRow"];

const ExploreMore = ({ noFooterLinks }) => {
  const { currentSubPage, currentLastRow } = useContext(AppContext);

  const router = useRouter();

  const [showCopy, setShowCopy] = useState(true);

  const [showBorder, setShowBorder] = useState(true);

  const breakpoint = useBreakpointIndex();

  useEffect(() => {
    setShowBorder(!rowsNoBorder.includes(currentLastRow));

    if (breakpoint < 2 && mobileRowsNoBorder.includes(currentLastRow)) {
      setShowBorder(false);
    } else {
      setShowBorder(true);
    }
  }, [currentLastRow, breakpoint]);

  useEffect(() => {
    if (/\/gold-tower|\/blue-tower/.test(router.asPath)) {
      setShowCopy(false);
    } else {
      setShowCopy(true);
    }
  }, [router.asPath]);

  return (
    <NavWrapper>
      {showBorder && <BorderTop />}
      {!noFooterLinks && (
        <Wrapper mb={[3, 4]} mt={[0, 0, 0]}>
          <div>
            <CopyWrapper mb={[3, 3, 4]} pt={[1, "20px"]}>
              <Text variant="smallCap">
                Explore{" "}
                {currentSubPage?.slug !== "/" ? currentSubPage?.title : "more"}
              </Text>
            </CopyWrapper>
            <CopyWrapper mb={[3, 3, 0]} sx={{ maxWidth: "480px" }}>
              {showCopy && (
                <Text variant="footOverview">
                  We are patient, opportunity-led and driven to preserve our
                  investors’ wealth.
                </Text>
              )}
            </CopyWrapper>
          </div>
          <ExploreMoreItems />
        </Wrapper>
      )}
    </NavWrapper>
  );
};
export default ExploreMore;

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-template-columns: 1fr 1fr;
  }
`;

const CopyWrapper = styled(Box)`
  ${({ theme }) =>
    theme.mq({
      display: ["none", "none", "block"],
    })};
`;
const NavWrapper = styled(Box)`
  position: relative;
  padding-top: 1px;
`;
const BorderTop = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey[4]};
  width: 100%;
  height: 1px;
  top: -1px;
  position: absolute;
  left: 0;
  right: 0;
`;
