import gql from "graphql-tag";

export const GET_ALL_CONTENT_PAGES_HOME = gql`
  query ContentPage {
    pageCollection(
      where: { OR: [{ site_contains: "marquette" }, { isHome: true }] }
    ) {
      items {
        slug
        parent {
          slug
        }
      }
    }
  }
`;
export const GET_ALL_SUB_PAGES = gql`
  query ContentPage {
    pageCollection(
      where: { AND: [{ site_not_contains: "marquette" }, { isHome: false }] }
    ) {
      items {
        site
        slug
        parent {
          slug
        }
      }
    }
  }
`;

export const GET_ROWS = gql`
  query ContentPage($slug: String!, $site: String, $preview: Boolean!) {
    pageCollection(
      where: {
        OR: [
          { AND: [{ isHome: true }, { slug: $slug }] }
          { AND: [{ site: $site }, { slug: $slug }] }
        ]
      }
      limit: 1
      preview: $preview
    ) {
      items {
        title
        slug
        isHome
        site
        heroSlideCollection(limit: 10) {
          items {
            url
            width
            height
            description
          }
        }
        overviewBody {
          json
        }
        seo {
          title
          description
          image {
            url
          }
        }
        rowsCollection(limit: 15) {
          items {
            ... on ContentRow {
              title
              theme
              label
              copy {
                json
              }
              mediaCollection(limit: 15) {
                items {
                  width
                  height
                  url
                  contentType
                  fileName
                }
              }
              video
              videoThumbnail {
                url
                width
                height
              }
            }
            ... on ContactRow {
              title
              label
              copy {
                json
              }
              formType
            }
            ... on HeroRow {
              title
              label
              mediaCollection(limit: 15) {
                items {
                  width
                  height
                  url
                  contentType
                }
              }
            }
            ... on TableRow {
              table
              label
              heading
              body {
                json
              }
              title
              style
              ctaButtonText
              ctaButtonLinkUrl
              ctaButtonLinkPage {
                slug
                title
              }
              imageLink
              video
              imageCollection(limit: 15) {
                items {
                  width
                  height
                  url
                }
              }
            }
            ... on AccordionRow {
              title
              label
              heading
              style
              accordionItemCollection {
                items {
                  heading1
                  heading2
                  image {
                    width
                    height
                    url
                  }
                  body {
                    json
                  }
                }
              }
            }
            ... on PageLinkRow {
              label
              style
              heading
              body {
                json
              }
              mediaCollection(limit: 15) {
                items {
                  url
                  width
                  height
                }
              }
              pageLinkItemsCollection {
                items {
                  linkUrl
                  linkPage {
                    slug
                    title
                    site
                    parent {
                      slug
                    }
                  }
                  image {
                    width
                    height
                    url
                  }
                  title
                  details
                }
              }
            }
            ... on CopyRow {
              heading
              body {
                json
              }
            }
            ... on DownloadRow {
              title
              heading
              file {
                url
              }
            }
            ... on SpotifyRow {
              entryTitle
              label
              copy {
                json
              }
              spotifySourceUrl
            }
            ... on StackRow {
              title
              label
              stackRowItemsCollection {
                items {
                  heading
                  body {
                    json
                  }
                }
              }
            }
            ... on GalleryRow {
              title
              label
              heading
              linkText
              linkUrl
              linkPage {
                slug
                title
                site
                parent {
                  slug
                }
              }
              carouselCollection {
                items {
                  width
                  height
                  url
                }
              }
            }
            ... on ImageRow {
              title
              image {
                width
                height
                url
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_NAVIGATION = gql`
  query ContentPage($slug: String!) {
    navigationCollection(where: { slug: $slug }, limit: 20) {
      items {
        theme
        slug
        title
        noFooterLinks
        navigationItemsCollection {
          ... on NavigationNavigationItemsCollection {
            items {
              title
              pageLink {
                ... on Page {
                  slug
                }
              }
            }
          }
        }
        contactLinkText
        contactLinkUrl {
          ... on Page {
            slug
          }
        }
      }
    }
  }
`;
