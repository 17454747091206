import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { AppContext } from "contexts/AppContext";
import ExploreMoreItem from "./ExploreMoreItem";

const ExploreMoreItems = ({ onClick, isNavMenu, ...rest }) => {
  const { currentNavLinks, currentContactNav, currentSubPage } =
    useContext(AppContext);

  if (currentNavLinks.length === 0) return null;

  return (
    <Wrapper {...rest}>
      {currentNavLinks?.map(({ label, href }, i) => (
        <ExploreMoreItem
          href={href}
          key={`explore-more-items-${i}`}
          isNavMenu={isNavMenu}
          onClick={onClick}
        >
          {label}
        </ExploreMoreItem>
      ))}
      {currentSubPage && currentSubPage.slug === "/" && (
        <ExploreMoreItem
          href="https://investors.marquette.com.au/login"
          isBlank={true}
          isNavMenu={isNavMenu}
          passHref
        >
          Investor Portal
        </ExploreMoreItem>
      )}
      <ExploreMoreItem
        href={currentContactNav?.url}
        isNavMenu={isNavMenu}
        onClick={onClick}
      >
        {currentContactNav?.label}
      </ExploreMoreItem>
    </Wrapper>
  );
};
export default ExploreMoreItems;

const Wrapper = styled(Box)``;
