import React from "react";

const SvgPlayButton = ({ ...rest }) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="20.5" cy="20.5" r="20.5" fill="white" />
      <path
        d="M29 20.4999L16.25 27.8611L16.25 13.1387L29 20.4999Z"
        fill="#222222"
      />
    </svg>
  );
};

export default SvgPlayButton;
