import React from "react";

const SvgCloseMenu = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.42871"
        y="0.72168"
        width="21"
        height="1"
        transform="rotate(45 1.42871 0.72168)"
        fill="white"
      />
      <rect
        x="0.72168"
        y="15.5708"
        width="21"
        height="1"
        transform="rotate(-45 0.72168 15.5708)"
        fill="white"
      />
    </svg>
  );
};
export default SvgCloseMenu;
