import { motion } from "framer-motion";
import { useRouter } from "next/router";
import React from "react";

const HeroSlidePageAnimation = ({ children }) => {
  const router = useRouter();

  const variant = {
    initial: { opacity: 0 },
    enter: {
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.48, 0.15, 0.25, 0.96],
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.48, 0.15, 0.25, 0.96],
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit={router.asPath !== "/" ? "exit" : null}
      variants={variant}
      key={router.asPath}
      style={{
        overflow: "hidden",
      }}
    >
      {children}
    </motion.div>
  );
};

export default HeroSlidePageAnimation;
