import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { AppContext } from "contexts/AppContext";
import NavLink from "./NavLink";

const NavSubLogo = () => {
  const { currentSubPage } = useContext(AppContext);

  return (
    <Wrapper>
      {currentSubPage?.slug !== "/" && (
        <NavLink
          href={"/" + currentSubPage?.slug || "/"}
          variant="subLogo"
          passHref
        >
          {currentSubPage?.title}
        </NavLink>
      )}
    </Wrapper>
  );
};
export default NavSubLogo;

const Wrapper = styled(Box)`
  a {
    color: ${({ theme }) => theme.colors.text} !important;
    font-family: ${({ theme }) => theme.fonts.bold};
    line-height: 100% !important;
    display: inline-block;
    letter-spacing: 1px;
  }
  width: 30px;

  ${({ theme }) =>
    theme.mq({
      paddingTop: ["3px", "3px", 0],
    })};

  ${({ theme }) => theme.mediaQueries.medium} {
    a {
      color: inherit;
      font-family: ${({ theme }) => theme.fonts.light};
      line-height: inherit;
      letter-spacing: unset;
    }

    width: unset;
  }
`;
