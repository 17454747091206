import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import { keyframes } from "@emotion/react";

const HeroSliderPaginationItem = ({
  isCurrent,
  firstLoadDone,
  slideTime,
  index,
  ...rest
}) => {
  return (
    <Container mr={[2]} {...rest}>
      <Wrapper>
        <ProgressBar
          isCurrent={isCurrent}
          firstLoadDone={firstLoadDone}
          index={index}
          slideTime={slideTime}
        />
      </Wrapper>
    </Container>
  );
};
export default HeroSliderPaginationItem;

const Container = styled(Box)`
  width: 100%;
  height: 32px;
  padding-top: 1rem;
  cursor: pointer;
`;

const Wrapper = styled(Box)`
  width: 100%;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.progressBar.bg};
  height: 2px;
  overflow: hidden;
  &:last-of-type {
    margin-right: 0;
  }
`;
const progressAnimation = (isCurrent) => keyframes`
    0% {
        width : 0%; 
    }
    100% {
        width :  ${isCurrent ? "100%" : "0%"};
    }
`;
const ProgressBar = styled(Box)`
  animation-name: ${({ isCurrent }) => progressAnimation(isCurrent)};
  animation-duration: ${({ slideTime, firstLoadDone }) =>
    parseInt(slideTime + (firstLoadDone ? 250 : 0))}ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-fill-mode: forwards;

  width: 0%;
  height: 100%;
  background: ${({ theme }) => theme.colors.progressBar.progress};
`;
