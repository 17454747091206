import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";

const ContentLayout = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};
export default ContentLayout;

const Wrapper = styled(Box)`
  display: grid;

  ${({ theme }) =>
    theme.mq({
      gridTemplateColumns: ["100%", "100%", "23% 1fr"],
    })};
`;
