import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Container as ContainerComponent } from "theme-ui";
import HeroSliderPaginationItem from "./HeroSliderPaginationItem";

const HeroSliderPagination = ({
  heroSlides,
  currentIndex,
  slideTime,
  swiper,
}) => {
  const [firstLoadDone, setFirstLoadDone] = useState(false);

  const handleClick = (idx) => {
    swiper.slideTo(idx);
  };

  useEffect(() => {
    if (!firstLoadDone && currentIndex > 0) {
      setFirstLoadDone(true);
    }
  }, [currentIndex]);

  return (
    heroSlides?.length > 1 && (
      <Wrapper mt={[2, 3]}>
        {Array(heroSlides?.length || 0)
          .fill(0)
          .map((a, i) => (
            <HeroSliderPaginationItem
              key={`hero-slider-pagination-${i}`}
              slideTime={slideTime}
              isCurrent={currentIndex === i}
              index={i}
              onClick={() => handleClick(i)}
              firstLoadDone={firstLoadDone}
            />
          ))}
      </Wrapper>
    )
  );
};
export default HeroSliderPagination;

const Wrapper = styled(Box)`
  z-index: 1000;
  width: 100%;
  display: flex;
`;
