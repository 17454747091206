import React from "react";
import styled from "@emotion/styled";
import { Box, Container, Text } from "theme-ui";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { head } from "lodash";

const Overview = ({ data, ...rest }) => {
  const { heading, body } = data || {};
  if (!heading && !body) return null;

  const renderOption = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text variant="pageOverview" as="h1">
          {children}
        </Text>
      ),
    },
  };

  return (
    <Wrapper {...rest}>
      {body && documentToReactComponents(body?.json, renderOption)}
    </Wrapper>
  );
};
export default Overview;

const Wrapper = styled(Box)`
  width: 95%;
  ${({ theme }) => theme.mediaQueries.medium} {
    width: 50%;
  }
`;
