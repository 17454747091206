import "styles/fonts.css";

import React, { useEffect } from "react";
import { useRouter } from "next/dist/client/router";

import { ApolloProvider } from "@apollo/client";
import { useApollo } from "apollo/client";

import { Global, css } from "@emotion/react";
import { ThemeProvider } from "theme-ui";
import theme from "theme";

import { AnimatePresence } from "framer-motion";
import PageAnimWrap from "components/PageAnimWrap";
import Layout from "components/Global/Layout";

import { AppContextProvider } from "contexts/AppContext";
import TagManager from "react-gtm-module";
import { UserPreferencesProvider } from "contexts/userPreferences";

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <UserPreferencesProvider>
            <GlobalStyle theme={theme} />
            <AnimatePresence
              exitBeforeEnter
              onExitComplete={() => window.scrollTo(0, 0)}
              initial={false}
            >
              <Layout motionKey={router.asPath}>
                <Component {...pageProps} />
              </Layout>
            </AnimatePresence>
          </UserPreferencesProvider>
        </AppContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;

const GlobalStyle = (props) => {
  return (
    <Global
      styles={css`
        html {
          font-family: sans-serif;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
        }
        * {
          box-sizing: border-box;
        }
        a {
          color: currentColor;
          text-decoration: none;
        }
        body {
          font-family: ${props.theme.fonts.body};
        }
        svg {
          display: bl;
        }
        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          vertical-align: baseline;
          font-weight: normal;
        }
        sup {
          vertical-align: super;
          font-size: smaller;
        }
        button {
          font-family: inherit;
        }
      `}
    />
  );
};
