import React, { useEffect, useContext } from "react";
import styled from "@emotion/styled";
import { Box, Container } from "theme-ui";
import Nav from "./Nav";
import HeroSlider from "components/HeroSlide";
import Footer from "./Footer";
import { AppContext } from "contexts/AppContext";
import { useRouter } from "next/router";
import PageAnimWrap from "components/PageAnimWrap";
import usePageData from "hooks/usePageData";
import hideHeroSlugs from "data/hideHeroSlugs.json"

const Layout = ({ children, motionKey }) => {
  const { showHeroSlide, setShowHeroSlide } = useContext(AppContext);
  const router = useRouter();
  useEffect(()=> {
    if (hideHeroSlugs.includes(router.pathname)) {
      setShowHeroSlide(false) 
    }
  }, [router.asPath])

  return (
    <>
      <Nav />
      <PageAnimWrap motionKey={motionKey}>
        <HeroSlider show={showHeroSlide} />
        {children}
        <Footer />
      </PageAnimWrap>
    </>
  );
};
export default Layout;
