import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import SvgHeroSlideScrollBottom from "components/Svg/SvgHeroSlideScrollBottom";
import { animateScroll as scroll } from "react-scroll";

const HeroSlideScrollBottomButton = () => {
  const handleClick = () => {
    scroll.scrollTo(window.innerHeight, {
      duration: 500,
      smooth: true,
    });
  };

  return (
    <Wrapper onClick={handleClick} pb={[1, 2]}>
      <SvgHeroSlideScrollBottom />
    </Wrapper>
  );
};
export default HeroSlideScrollBottomButton;

const Wrapper = styled(Box)`
  align-self: flex-end;
  cursor: pointer;

  ${({ theme }) =>
    theme.mq({
      width: ["20px", "24px"],
    })};

  svg {
    width: 100%;
    height: 100%;
    display: block;
    ${({ theme }) => theme.transitions.arrowShift};
  }

  svg path {
    fill: ${({ theme }) => theme.colors.white[1]};
  }

  &:hover {
    svg {
      transform: translateY(8px);
    }
  }
`;
