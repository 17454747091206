import React from "react";

const SvgMenu = () => {
  return (
    <svg
      width="25"
      height="15"
      viewBox="0 0 25 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="25"
        height="1"
        transform="matrix(1 0 0 -1 0 1)"
        fill="white"
      />
      <rect
        width="25"
        height="1"
        transform="matrix(1 0 0 -1 0 8)"
        fill="white"
      />
      <rect
        width="25"
        height="1"
        transform="matrix(1 0 0 -1 0 15)"
        fill="white"
      />
    </svg>
  );
};

export default SvgMenu;
