import React from "react";

const SvgArrowRight = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2546 8.17261L10.082 0L8.82495 1.25708L16.9976 9.42969L8.82742 17.5998L10.0845 18.8569L19.5126 9.42882L18.2555 8.17174L18.2546 8.17261Z"
        fill="white"
      />
      <path d="M0 8.54319H17.7778V10.321H0V8.54319Z" fill="white" />
    </svg>
  );
};

export default SvgArrowRight;
