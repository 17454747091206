import React from "react";

const SvgArrowUp = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50073 1.93091L0.328125 10.1035L1.5852 11.3606L9.75781 3.18799L17.928 11.3581L19.185 10.1011L9.75694 0.67296L8.49987 1.93004L8.50073 1.93091Z"
        fill="white"
      />
      <path
        d="M8.87131 20.1855L8.87131 2.40777L10.6491 2.40777L10.6491 20.1855L8.87131 20.1855Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgArrowUp;
