import React, { useState } from "react";

import styled from "@emotion/styled";
import { Box, Button, Text } from "theme-ui";
import ContentLayout from "../ContentLayout";
import NavLogo from "./NavLogo";
import SvgMenu from "components/Svg/SvgMenu";
import NavMobileMenu from "./NavMobileMenu";
import SvgCloseMenu from "components/Svg/SvgCloseMenu";
import NavSubLogo from "./NavSubLogo";

const NavMobile = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu((_) => !_);
  };

  return (
    <>
      <Wrapper>
        <Left>
          <NavLogo onClick={() => setShowMenu(false)} />
        </Left>
        <Right>
          <NavSubLogo />
          <SvgWrapper onClick={handleToggleMenu}>
            {showMenu ? <SvgCloseMenu /> : <SvgMenu />}
          </SvgWrapper>
        </Right>
      </Wrapper>
      {showMenu && <NavMobileMenu handleToggleMenu={handleToggleMenu} />}
    </>
  );
};

export default NavMobile;

const Wrapper = styled(ContentLayout)`
  width: 100%;
  height: ${({ theme }) => theme.navHeights[0]};
  position: relative;

  ${({ theme }) =>
    theme.mq({
      display: ["flex", "flex", "none"],
    })};

  align-items: center;
  justify-content: space-between;
`;
const SvgWrapper = styled(Box)`
  svg rect {
    fill: ${({ theme }) => theme.colors.white[1]};
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
`;
const Left = styled(Box)`
  flex: 1;
`;
const Right = styled(Box)`
  flex: 1;
  display: flex;

  justify-content: space-between;
`;
