import React from "react";

const SvgArrowDown = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.011 18.9285L19.1836 10.7559L17.9265 9.49878L9.75391 17.6714L1.58376 9.50124L0.326685 10.7583L9.75477 20.1864L11.0119 18.9293L11.011 18.9285Z"
        fill="white"
      />
      <path
        d="M10.6404 0.673828L10.6404 18.4516L8.86263 18.4516L8.86263 0.673828L10.6404 0.673828Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgArrowDown;
