import facepaint from "facepaint";
import dark from "./colors/dark";
import light from "./colors/light";

const breakpoints = ["650px", "950px"];
const navHeights = ["64px", "71px"];
const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];
const fonts = {
  body: "CentraNo1-Light, sans-serif",
  bodyItalic: '"CentraNo1-LightItalic", sans-serif',
  bold: '"CentraNo1-Book", sans-serif',
  monospace: '"CentraNo1-Light", sans-serif',
};
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp})`));
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96];
const fontWeights = {
  body: 400,
  heading: 700,
  bold: 700,
};
const lineHeights = {
  body: 1.5,
  heading: 1.125,
};
const colors = {
  ...dark,
  modes: {
    light: { ...light },
  },
};
const text = {
  default: {
    color: "text",
    fontSize: ["18px", "24px"],
  },
  xs: {
    fontSize: ["10px", "15px"],
  },
  medium: {
    fontSize: ["18px", "28px"],
  },
  nav: {
    fontSize: ["12px", "14px"],
    fontFamily: fonts.bold,
  },
  footer: {
    fontSize: ["16px", "19px"],
  },
  footerMicro: {
    fontSize: ["9px", "11px"],
  },
  heading: {
    fontSize: ["29px", "59px"],
  },
  tableDataHeading: {
    fontSize: ["42px", "56px"],
  },
  tableDataBody: {
    fontSize: ["12px", "15px"],
    textTransform: "uppercase",
  },
  tableDefault: {
    fontSize: ["14px", "20px"],
  },
  body: {
    fontSize: ["22px", "28px"],
    color: "grey.6",
  },
  accordionText: {
    fontSize: ["18px", "21px"],
    lineHeights: ["120%"],
  },
  stackRowHeading: {
    fontSize: ["18px", "21px"],
    lineHeights: ["120%"],
    fontFamily: fonts.bold,
  },
  stackRowBody: {
    fontSize: ["18px", "21px"],
    lineHeights: ["120%"],
  },
  pageLinkHeader: {
    fontSize: ["18px", "21px"],
  },
  pageLinkBody: {
    fontSize: ["18px", "21px"],
  },
  pageOverview: {
    fontSize: ["22px", "34px"],
    fontFamily: fonts.bold,
    fontWeight: 500,
  },
  footOverview: {
    fontSize: ["22px", "34px"],
  },
  copyHeading: {
    fontSize: ["18px", "25px"],
    fontFamily: fonts.bold,
  },
  copyHeading4: {
    fontSize: ["18px", "24px"],
    lineHeights: ["120%"],
    color: "white.1",
  },
  copyBody: {
    fontSize: ["18px", "24px"],
    lineHeights: ["120%"],
    color: "grey.6",
  },
  smallCap: {
    fontSize: ["12px", "15px"],
    lineHeights: ["120%"],
    color: "grey.6",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: fonts.bold,
  },
  formHeading: {
    fontSize: ["12px", "15px"],
    lineHeights: ["120%"],
    color: "grey.1",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFamily: fonts.bold,
  },
  formLabel: {
    fontSize: ["16px", "21px"],
    color: "grey.6",
  },
  formErrorMessage: {
    fontSize: ["12px", "15px"],
    color: "grey.1",
  },
  exploreMoreLink: {
    fontSize: ["16px", "20px"],
  },
  exploreMoreHeading: {
    fontSize: ["16px", "20px"],
    textTransform: "uppercase",
  },
  logoText: {
    fontSize: "11px",
    textTransform: "uppercase",
    fontFamily: fonts.bold,
    lineHeight: "100%",
    letterSpacing: "1px",
  },
  subLogo: {
    fontSize: ["11px", "11px", "14px"],
    textTransform: "uppercase",
  },
  heading404: {
    fontSize: ["70px", "100px"],
  },
  text404: {
    fontSize: ["16px", "21px"],
  },
};
const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
};
const zIndex = {
  dropdown: 1000,
  sticky: 1020,
  fixed: 1030,
  modalBackdrop: 1040,
  modal: 1050,
  tooltip: 1070,
  nav: 1080,
};
const layout = {
  container: {
    maxWidth: ["100%", "1440px"],
  },
};
const radii = {
  global: "8px",
};
const buttons = {
  primary: {
    cursor: "pointer",
    color: "background",
    bg: "white.1",
    borderRadius: "50px",
    padding: "14px 26px",
    fontSize: "19px",
  },
  secondary: {
    cursor: "pointer",
    color: "white.1",
    bg: "grey.1",
    borderRadius: "50px",
    padding: "14px 26px",
    fontSize: "19px",
  },
  downloadButton: {
    cursor: "pointer",
    color: "background",
    bg: "white.1",
    borderRadius: "50px",
    padding: ["10px 16px", "14px 30px"],
    fontSize: ["14px", "16px"],
  },
  pill: {
    cursor: "pointer",
    color: "white.1",
    bg: "grey.1",
    borderRadius: "50px",
    padding: ["10px 16px", "14px 30px"],
    fontSize: ["14px", "16px"],
  },
  "pill-small": {
    cursor: "pointer",
    color: "white.1",
    bg: "grey.1",
    borderRadius: "50px",
    padding: ["4x 8px"],
    lineHeight: "100%",
    fontSize: ["15px"],
    "&:disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
    },
  },
};
const timingFunctions = {
  cubicBezier: "cubic-bezier(0.075, 0.82, 0.165, 1)",
};
const transitions = {
  arrowShift: `transition: opacity 250ms ${timingFunctions.cubicBezier},
  transform 500ms 50ms ${timingFunctions.cubicBezier};`,
};
const maxWidth = {
  copy: "83%",
};
export default {
  config: {
    initialColorModeName: "dark",
  },
  breakpoints,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  colors,
  text,
  mediaQueries,
  zIndex,
  layout,
  navHeights,
  radii,
  buttons,
  mq,
  maxWidth,
  transitions,
};
