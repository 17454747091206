import React, { createContext, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [showHeroSlide, setShowHeroSlide] = useState(true);
  const [watchScroll, setWatchScroll] = useState(true);
  const [heroLogo, setHeroLogo] = useState(<></>);
  const [heroCta, setHeroCta] = useState({
    body: "We buy quality properties to make them better and achieve high-risk returns out of low-risk properties.",
    linkText: "Learn more",
  });
  const [heroSlides, setHeroSlides] = useState([]);
  const [currentNavLinks, setCurrentNavLinks] = useState([]);
  const [currentSubNavLogo, setCurrentSubNavLogo] = useState(false);
  const [allParents, setAllParents] = useState([]);
  const [homeOverview, setHomeOverview] = useState("");
  const [currentSubPage, setCurrentSubPage] = useState({});
  const [currentLastRow, setCurrentLastRow] = useState("");
  const [currentContactNav, setCurrentContactNav] = useState(null);
  const [navigationData, setNavigationData] = useState(null);

  const contextValues = {
    showHeroSlide,
    setShowHeroSlide,
    watchScroll,
    setWatchScroll,
    currentNavLinks,
    setCurrentNavLinks,
    heroLogo,
    setHeroLogo,
    heroSlides,
    setHeroSlides,
    heroCta,
    setHeroCta,
    currentSubNavLogo,
    setCurrentSubNavLogo,
    allParents,
    setAllParents,
    homeOverview,
    setHomeOverview,
    currentSubPage,
    setCurrentSubPage,
    currentLastRow,
    setCurrentLastRow,
    currentContactNav,
    setCurrentContactNav,
    navigationData,
    setNavigationData,
  };
  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};
