import React, { useContext, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Box, Button, Container, Text } from "theme-ui";

import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

const Nav = () => {
  const navRef = useRef();

  useEffect(() => {
    let lastScrollTop;
    const { current: navbar } = navRef;
    window.addEventListener("scroll", function () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        navbar.style.top = "-80px";
      } else {
        navbar.style.top = "0";
      }
      lastScrollTop = scrollTop;
    });
  }, []);

  return (
    <Wrapper ref={navRef} pt={["7px"]}>
      <Container px={[3, 4]}>
        <NavDesktop />
        <NavMobile />
      </Container>
    </Wrapper>
  );
};
export default Nav;

const Wrapper = styled(Box)`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.nav};
  transition: top 0.5s;
  background-color: ${({ theme }) => theme.colors.background};
`;
