export default {
  text: "#222222",
  background: "#ffffff",
  grey: {
    1: "#222222",
    2: "#2b2b2b",
    3: "#3C3C3C",
    4: "#D8D8D8",
    5: "#4A4A4A",
    6: "#747474",
    7: "#8B8B8B",
    8: "#BDBDBD",
  },
  imagePlaceholderBg: "#D8D8D8",
  rowBg: "#ffffff",
  white: {
    1: "#222222",
  },
  progressBar: {
    bg: "#D8D8D8",
    progress: "#3C3C3C",
  },
  rows: {
    galleryRow: {
      bg: "#F9F9F9",
    },
    stackRow: {
      bodyCopy: { text: "#222222" },
    },
  },
};
