import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import SvgScrollTop from "components/Svg/SvgScrollTop";
import { animateScroll as scroll } from "react-scroll";

const ScrollTopButton = () => {
  const handleClick = () => {
    scroll.scrollToTop({
      to: 0,
      duration: 500,
      smooth: true,
    });
  };

  return (
    <Wrapper onClick={handleClick}>
      <SvgScrollTop />
    </Wrapper>
  );
};
export default ScrollTopButton;

const Wrapper = styled(Box)`
  cursor: pointer;
  ${({ theme }) =>
    theme.mq({
      width: ["18px", "24px"],
    })};
  svg {
    width: 100%;
    height: 100%;
    display: block;
    ${({ theme }) => theme.transitions.arrowShift};
  }
  svg path {
    fill: ${({ theme }) => theme.colors.text};
  }

  &:hover {
    svg {
      transform: translateY(-8px);
    }
  }
`;
