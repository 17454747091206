import React from "react";

const SvgLogo = () => {
  return (
    <svg
      width="16"
      height="37"
      viewBox="0 0 16 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85986 5.52546V2.11492L8.85986 0V3.4169L6.87029 5.51911L6.85986 5.52546Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7188 9.56291V29.3089L13.7292 29.3057L15.7188 27.2028V7.44727L13.7188 9.56291Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85986 17.0284V36.7747L6.87029 36.7715L8.85986 34.6689V14.9131L6.85986 17.0284Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.56291V29.3089L0.0104439 29.3057L2 27.2028V7.44727L0 9.56291Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgLogo;
