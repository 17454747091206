import { useQuery } from "@apollo/client";
import {
  GET_ALL_CONTENT_PAGES_HOME,
  GET_ALL_SUB_PAGES,
  GET_NAVIGATION,
  GET_ROWS,
} from "queries";
import slugToTitleCase from "utils/slugToTitleCase";

const DEFAULT_HOME = "Marquette";

const usePageData = (slug, params, preview) => {
  switch (slug) {
    case "home":
      return useQuery(GET_ROWS, {
        variables: { slug, site: DEFAULT_HOME, preview },
      });
    case "pageSlug":
      return useQuery(GET_ROWS, {
        variables: { slug: params, site: DEFAULT_HOME, preview },
      });
    case "subPage":
      return useQuery(GET_ROWS, {
        variables: {
          slug: params?.subPage,
          site: slugToTitleCase(params?.pageSlug),
          preview,
        },
      });
    case "navigation":
      return useQuery(GET_NAVIGATION, {
        variables: { slug: params, preview },
      });
    case "all_pages_home_parent":
      return useQuery(GET_ALL_CONTENT_PAGES_HOME);
    case "all_pages_subpage_parent":
      return useQuery(GET_ALL_SUB_PAGES);
  }
};

export default usePageData;
