import React from "react";

const SvgArrowLeft = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25708 10.6848L9.42969 18.8574L10.6868 17.6003L2.51416 9.42774L10.6843 1.25759L9.42722 0.000513958L-0.000868668 9.4286L1.25621 10.6857L1.25708 10.6848Z"
        fill="white"
      />
      <path
        d="M19.5117 10.3142L1.73394 10.3142L1.73394 8.53646L19.5117 8.53646L19.5117 10.3142Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgArrowLeft;
