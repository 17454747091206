import React, { useContext } from "react";
import NavLink from "./NavLink";
import styled from "@emotion/styled";
import { Box, Button, Text } from "theme-ui";
import { AppContext } from "contexts/AppContext";
import NavLogo from "./NavLogo";
import NavSubLogo from "./NavSubLogo";

const NavDesktop = () => {
  const { currentNavLinks, currentContactNav, currentSubPage } =
    useContext(AppContext);

  return (
    <Wrapper>
      <Left>
        <NavLogo />
        <NavSubLogo />
      </Left>
      <Right>
        <PageLinks>
          {currentNavLinks?.map(({ label, href }, i) => (
            <NavLink href={href} variant="nav" mr={[2, 3]} key={`navlink-${i}`}>
              {label}
            </NavLink>
          ))}
        </PageLinks>
        <EndLinks>
          {currentSubPage && currentSubPage.slug === "/" && (
            <NavLink
              href="https://investors.marquette.com.au/login"
              variant="nav"
              target="_blank"
              passHref
              mr={[2, 3]}
            >
              <InvestorSpan>Investor Portal</InvestorSpan>
            </NavLink>
          )}
          {currentContactNav && (
            <NavLink href={currentContactNav?.url} variant="nav" passHref>
              {currentContactNav?.label}
            </NavLink>
          )}
        </EndLinks>
      </Right>
    </Wrapper>
  );
};

export default NavDesktop;

const Wrapper = styled(Box)`
  width: 100%;
  height: ${({ theme }) => theme.navHeights[1]};
  position: relative;
  @media screen and (max-width: 1150px) {
    grid-template-columns: 0.8fr 1.2fr;
  }
  ${({ theme }) =>
    theme.mq({
      display: ["none", "none", "grid"],
    })};
  grid-template-columns: 1fr 1fr;
  text-transform: uppercase;
`;
const PageLinks = styled(Box)``;

const EndLinks = styled(Box)``;

const Side = styled(Box)`
  display: flex;
  align-items: center;
`;
const Left = styled(Side)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Right = styled(Side)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InvestorSpan = styled.span`
  white-space: nowrap;
`;
