import React from "react";
import NextLink from "next/link";
import { Box, Text as TextComponent } from "theme-ui";
import SvgExternalArrow from "./Svg/SvgExternalArrow";
import styled from "@emotion/styled";

const Link = ({
  href = "/",
  variant = "default",
  external,
  children,
  noArrow,
  ...rest
}) => {
  return (
    <NextLink href={href} passHref>
      <Text
        as="a"
        variant={variant}
        target={external ? "__target" : null}
        {...rest}
      >
        {children}
        {external && !noArrow && (
          <ExternalIconWrapper ml={[2]}>
            <SvgExternalArrow />
          </ExternalIconWrapper>
        )}
      </Text>
    </NextLink>
  );
};

export default Link;

const Text = styled(TextComponent)``;
const ExternalIconWrapper = styled(Box)`
  &,
  svg {
    display: inline;
  }

  svg path {
    fill: currentColor;
  }
`;
