import React from "react";
import styled from "@emotion/styled";
import { Box } from "theme-ui";
import SvgArrowRight from "components/Svg/Arrow/SvgArrowRight";
import SvgArrowLeft from "components/Svg/Arrow/SvgArrowLeft";
import SvgArrowDown from "components/Svg/Arrow/SvgArrowDown";
import SvgArrowUp from "components/Svg/Arrow/SvgArrowUp";

const Arrow = ({ dir = "right", hover = false, width, ...rest }) => {
  return (
    <ArrowWrapper {...rest} dir={dir} hover={hover} width={width}>
      {(() => {
        switch (dir) {
          case "left":
            return <SvgArrowLeft />;
          case "up":
            return <SvgArrowUp />;
          case "down":
            return <SvgArrowDown />;
          case "right":
          default:
            return <SvgArrowRight />;
        }
      })()}
    </ArrowWrapper>
  );
};
export default Arrow;

const ArrowWrapper = styled(Box)`
  svg {
    width: 100%;
    height: 100%;
  }

  svg path {
    fill: ${({ theme }) => theme.colors.text};
  }
  ${({ theme, width }) =>
    theme.mq({
      width,
    })};
`;
