export default {
  text: "#ffff",
  background: "#222222",
  grey: {
    1: "#222222",
    2: "#2b2b2b",
    3: "#3C3C3C",
    4: "#444444",
    5: "#4A4A4A",
    6: "#888",
    7: "#8B8B8B",
    8: "#BDBDBD",
  },
  imagePlaceholderBg: "#4A4A4A",
  rowBg: "transparent",
  white: {
    1: "#ffffff",
  },
  progressBar: {
    bg: "#444444",
    progress: "#ffffff",
  },
  rows: {
    galleryRow: {
      bg: "#1B1B1B",
    },
    stackRow: {
      bodyCopy: { text: "#888" },
    },
  },
};
