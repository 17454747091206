import React, { useRef, useState, useEffect } from "react";
import Hls from "hls.js";
import styled from "@emotion/styled";

const HeroVideo = ({
  mediaUrl,
  videoRef,
  muxPlaybackId,
  videoThumbnail,
  videoSettings,
}) => {
  const [streamUrl, setStreamUrl] = useState(mediaUrl || null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [videoPoster] = useState(videoThumbnail?.url || null);

  const videoLoaded = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    let hls;
    const src = streamUrl;
    if (videoRef.current && streamUrl !== "") {
      const video = videoRef.current;
      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef, streamUrl]);

  useEffect(() => {
    if (muxPlaybackId) {
      setStreamUrl(`https://stream.mux.com/${muxPlaybackId}.m3u8`);
    }
  }, [muxPlaybackId]);
  return (
    <>
      {streamUrl && (
        <Wrapper>
          <WrapperInner>
            <video
              className="video-player"
              ref={videoRef}
              src={streamUrl}
              poster={videoPoster}
              onCanPlayThrough={videoLoaded}
              width="100%"
              height="100%"
              {...videoSettings}
            ></video>
          </WrapperInner>
        </Wrapper>
      )}
    </>
  );
};

export default HeroVideo;

const Wrapper = styled.div`
  overflow: hidden;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  video {
    display: block;
    object-fit: cover;
  }
`;
const WrapperInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
