import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import { SwiperSlide } from "swiper/react";
import Media from "components/Media";

const HeroSlideBody = ({ src = "/", type }) => {
  return (
    <Wrapper>
      <Media
        type={type}
        src={`${src}?w=2800&q=80`}
        alt="Hero Image Slide"
        layout="fill"
        objectFit="cover"
        videoSettings={{
          autoPlay: true,
          loop: true,
          muted: true,
          controls: false,
          playsInline: true,
        }}
      />
      {/* <HeroVideoOverlay />
      <HeroVideoShadow /> */}
    </Wrapper>
  );
};

export default HeroSlideBody;

const Wrapper = styled(SwiperSlide)`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.imagePlaceholderBg};
`;

const HeroVideoOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
`;

const HeroVideoShadow = styled.div`
  position: absolute;
  width: 100%;
  height: 200px;
  left: 0px;
  bottom: 0px;
  z-index: 3;
  background: linear-gradient(180deg, #000000 0%, rgba(34, 34, 34, 0) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  opacity: 0.5;
  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 250px;
  }
`;
