import React from "react";

const SvgHeroSlideScrollBottom = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3195 1.75185L18.0625 0.494768L9.89159 8.66564L1.72058 0.494629L0.463501 1.75171L9.89145 11.1799L19.3195 1.75185Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgHeroSlideScrollBottom;
