import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Box, Button } from "theme-ui";
import Video from "./Video";
import SvgPlayButton from "components/Svg/SvgPlayButton";

const MediaVideo = ({ src, contentType, fileName, videoSettings, ...rest }) => {
  const videoRef = useRef(null);

  const [showPlayButton, setShowPlayButton] = useState(true);

  const handleClick = () => {
    if (videoRef?.current) {
      const { current: video } = videoRef;

      if (video.paused) {
        video.play();
        setShowPlayButton(false);
      } else {
        video.pause();
        setShowPlayButton(true);
      }
    }
  };

  const onPause = () => {
    setTimeout(() => {
      if (videoRef?.current) {
        const { current: video } = videoRef;
        if (video.paused) {
          setShowPlayButton(true);
        }
      }
    }, 200);
  };

  useEffect(() => {
    if (videoSettings?.autoPlay) setShowPlayButton(false);
  }, []);

  return (
    <Wrapper>
      <Video
        mediaUrl={src}
        videoRef={videoRef}
        contentType={contentType}
        fileName={fileName}
        videoSettings={{ ...videoSettings, onPause, controls: !showPlayButton }}
        {...rest}
      />
      {showPlayButton && (
        <PlayerButtonWrapper>
          <PlayerButton onClick={handleClick}>
            <SvgPlayButton />
          </PlayerButton>
        </PlayerButtonWrapper>
      )}
    </Wrapper>
  );
};
export default MediaVideo;

const Wrapper = styled(Box)``;
const PlayerButtonWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PlayerButton = styled(Box)`
  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: block;
  }

  ${({ theme }) =>
    theme.mq({
      width: ["45px", "65px"],
    })};
`;
