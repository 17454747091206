import React from "react";
import styled from "@emotion/styled";
import { Box, Text as TextComponent } from "theme-ui";
import { css } from "@emotion/react";

import LinkComponent from "components/Link";
import ScrollTopButton from "./ScrollTopButton";

const Footerbar = ({ isNavMenu }) => {
  return (
    <>
      <Wrapper py={[3, 4]}>
        <LeftSide mb={[4, 4, 0]}>
          <Text variant="footer">
            ©{new Date().getFullYear()} Marquette Properties
          </Text>
          <Text variant="footer">&nbsp;</Text>
        </LeftSide>
        <RightSide>
          <RightSideLinksWrapper>
            {/* <Box>
              <Box>
                <Link variant="footer" href="/privacy">
                  Privacy
                  <br />
                  Policy
                </Link>
              </Box>
            </Box> */}
            <Box>
              <Box>
                <Link
                  external
                  noArrow
                  variant="footer"
                  href="https://au.linkedin.com/company/marquette-properties-au"
                >
                  Connect
                </Link>
              </Box>
              <Box>
                <Link
                  variant="footer"
                  external
                  href="https://au.linkedin.com/company/marquette-properties-au"
                  sx={(theme) => ({
                    color: `${theme.colors.text} !important`,
                  })}
                >
                  Linkedln
                </Link>
              </Box>
            </Box>
            <ScrollWrapper pb={[3, 4]} isNavMenu={isNavMenu}>
              <ScrollTopButton />
            </ScrollWrapper>
          </RightSideLinksWrapper>
        </RightSide>
      </Wrapper>
      <Wrapper pb={["20px"]}>
        <Micro>
          <Text variant="footerMicro">
            Marquette Investment Managers Pty Ltd (ABN 53 147 490 042) is
            authorised and licensed by the Australian Securities and Investments
            Commission (ASFL 398526). ©{new Date().getFullYear()} Marquette
            Properties Pty Ltd (ABN 83 143 241 758). All rights reserved.
            {/* <Link href="/privacy" variant="footerMicro">
              Privacy Policy
            </Link> */}
          </Text>
        </Micro>
      </Wrapper>
    </>
  );
};
export default Footerbar;

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-template-columns: 1fr 1fr;
  }

  position: relative;
`;
const Link = styled(LinkComponent)`
  color: inherit;
`;
const Text = styled(TextComponent)`
  display: block;
  color: inherit;
`;
const LeftSide = styled(Box)`
  color: ${({ theme }) => theme.colors.grey[6]};
`;
const RightSide = styled(Box)`
  color: ${({ theme }) => theme.colors.white[1]};
`;
const ScrollWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  ${({ isNavMenu }) =>
    isNavMenu
      ? css`
          display: none;
        `
      : ``};
`;
const RightSideLinksWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: ${({ theme }) => theme.colors.grey[6]};
`;

const Micro = styled(Box)`
  color: ${({ theme }) => theme.colors.grey[6]};
`;
