import React from "react";

const SvgScrollTop = () => {
  return (
    <svg
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42809 6.42636e-06L-3.71748e-06 9.4281L1.25707 10.6852L9.42793 2.51432L17.599 10.6853L18.856 9.42826L10.685 1.25724L10.6852 1.25709L9.42809 6.42636e-06Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgScrollTop;
