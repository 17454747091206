import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import {
  AspectRatio as AspectRatioComponent,
  Box,
  Container as ThemeContainer,
} from "theme-ui";
import { Swiper, SwiperSlide } from "swiper/react";
import HeroSlideBody from "./HeroSlideBody";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import HeroSliderPagination from "./HeroSliderPagination";
import HeroSlidePageAnimation from "./HeroSlidePageAnimation";
import { AnimatePresence } from "framer-motion";
import { AppContext } from "contexts/AppContext";
import Overview from "components/Overview";
import useWindowSize from "hooks/useWindowSize";
import { useRouter } from "next/router";
import HeroSlideScrollBottomButton from "./HeroSlideScrollBottomButton";

const HeroSlider = ({ show }) => {
  const slideTime = 8000;

  const router = useRouter();

  const { heroLogo, heroSlides, homeOverview } = useContext(AppContext);

  const [currentIndex, setCurrentIndex] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const [relativeOverview, setRelativeOverview] = useState(false);
  const [overviewHeight, setOverviewHeight] = useState(0);

  const handleProgress = (swiper) => {
    setCurrentIndex(swiper?.activeIndex);
  };

  const { width, height } = useWindowSize();
  const heroSliderWrapperRef = useRef();
  const heroOverviewRef = useRef();

  useEffect(() => {
    if ((homeOverview, heroSliderWrapperRef?.current)) {
      const { height: heroHeight } =
        heroSliderWrapperRef?.current?.getBoundingClientRect();
      if (heroHeight < 435) {
        if (relativeOverview === false) setRelativeOverview(true);
      } else {
        if (relativeOverview === true) setRelativeOverview(false);
      }
    }
    setOverviewHeight(heroOverviewRef?.current?.getBoundingClientRect().height);
  }, [width, height, router, homeOverview]);

  return (
    <AnimatePresence exitBeforeEnter>
      {show && (
        <HeroSlidePageAnimation>
          <Container px={[3, 4]}>
            <Wrapper
              id="heroSliderWrapper"
              pt={["12px"]}
              ref={heroSliderWrapperRef}
              overviewHeight={overviewHeight}
            >
              <Swiper
                modules={[Autoplay, EffectFade]}
                autoplay={{
                  delay: slideTime,
                  disableOnInteraction: false,
                  autoplay: false,
                }}
                effect="fade"
                onActiveIndexChange={handleProgress}
                onAutoplayStart={() => setCurrentIndex(0)}
                preloadImages={true}
                onSwiper={(swiper) => {
                  setSwiper(swiper);
                }}
              >
                {heroSlides?.map(({ url, type, description }, i) => (
                  <SwiperSlide key={`heroslide-${i}`}>
                    <HeroSlideBody src={url} type={type} />
                    {description && <SlideCaption>{description}</SlideCaption>}
                  </SwiperSlide>
                ))}
              </Swiper>
            </Wrapper>
            <HeroSliderPagination
              heroSlides={heroSlides}
              slideTime={slideTime}
              swiper={swiper}
              currentIndex={currentIndex}
            />
            <HeroOverview py={[3, 4]} mt={[3, 4]}>
              <HeroOverviewWrapper ref={heroOverviewRef} pb={[2, 3]}>
                <Overview
                  data={{
                    body: homeOverview,
                  }}
                />
                <HeroSlideScrollBottomButton />
              </HeroOverviewWrapper>
            </HeroOverview>
          </Container>
        </HeroSlidePageAnimation>
      )}
    </AnimatePresence>
  );
};
export default HeroSlider;

const Wrapper = styled(Box)`
  position: relative;
  .swiper {
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
  border-radius: 8px;

  @media (min-height: 750px) {
    ${({ theme, overviewHeight }) =>
      theme.mq({
        height: [
          `calc(100vh - ${theme.navHeights[0]} - ${overviewHeight}px - 80px)`,
          `calc(100vh - ${theme.navHeights[1]} - ${overviewHeight}px - 132px)`,
        ],
      })};
  }

  height: 402px;
`;
const Container = styled(ThemeContainer)`
  @media (min-height: 750px) {
    height: 100vh;
    ${({ theme }) =>
      theme.mq({
        height: [
          `calc(100vh - ${theme.navHeights[0]} - 7px)`,
          `calc(100vh - ${theme.navHeights[1]} - 7px)`,
        ],
      })};
  }
  height: auto;
`;
const HeroOverview = styled(Box)``;
const HeroOverviewWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const SlideCaption = styled(Box)`
  background-color: #fff;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 4px 8px;
  border-radius: 5px;
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: uppercase;
  font-size: 12px;
`