import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import Arrow from "components/Arrow";
import Link from "next/link";
import { css } from "@emotion/react";

const ExploreMoreItem = ({ children, href, onClick, isNavMenu, isBlank }) => {
  return (
    <Link href={href || "/"} passHref>
      <Wrapper
        onClick={onClick}
        variant="exploreMoreLink"
        py={[3]}
        pr={[2, 3]}
        as="a"
        target={isBlank ? "_blank" : "_self"}
        isNavMenu={isNavMenu}
      >
        {children}
        <Arrow width={["12px", "14px"]} data-hover="explore-item" />
      </Wrapper>
    </Link>
  );
};
export default ExploreMoreItem;

const Wrapper = styled(Text)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey[4]};
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[4]};
  }

  ${({ isNavMenu, theme }) =>
    !isNavMenu
      ? css`
          & [data-hover="explore-item"] {
            opacity: 0;
            ${theme.transitions.arrowShift};
            transform: translateX(-10px);
          }
          &:hover {
            & [data-hover="explore-item"] {
              opacity: 1;
              transform: translateX(0);
            }
          }
        `
      : css`
          & [data-hover="explore-item"] {
            opacity: 1;
          }
        `};
`;
