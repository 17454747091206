import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Box, Container as ContainerComponent, Text } from "theme-ui";
import NavLogo from "../NavLogo";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import ExploreMoreItems from "components/Global/Footer/ExploreMore/ExploreMoreItems";
import Footerbar from "components/Global/Footer/Footerbar";

const NavMobileMenu = ({ handleToggleMenu }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    disableBodyScroll(wrapperRef?.current);
    return () => clearAllBodyScrollLocks();
  }, []);

  return (
    <Container px={[3, 4]} ref={wrapperRef}>
      <Wrapper>
        <ExploreMoreItems onClick={handleToggleMenu} mt={[4]} isNavMenu />
        <Footerbar isNavMenu />
      </Wrapper>
    </Container>
  );
};
export default NavMobileMenu;

const Container = styled(ContainerComponent)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.background};

  z-index: -1;

  ${({ theme }) =>
    theme.mq({
      paddingTop: [theme.navHeights[0], theme.navHeights[1]],
    })};
`;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
