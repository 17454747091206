import React, { useContext } from "react";
import styled from "@emotion/styled";
import {
  Box,
  Container as ContainerComponent,
  Text as TextComponent,
} from "theme-ui";

import ExploreMore from "./ExploreMore";
import Footerbar from "./Footerbar";
import { AppContext } from "contexts/AppContext";

const Footer = () => {
  const { navigationData } = useContext(AppContext);
  const { noFooterLinks } = navigationData || {};

  return (
    <Container noFooterLinks={noFooterLinks} px={[3, 4]}>
      <ExploreMore noFooterLinks={noFooterLinks} />
      <Footerbar />
    </Container>
  );
};
export default Footer;

const Container = styled(ContainerComponent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;
