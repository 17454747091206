import React from "react";
import MediaVideo from "./MediaVideo";
import MediaImage from "./MediaImage";

const Media = ({ type, videoSettings, ...rest }) => {
  return type === "video" ? (
    <MediaVideo videoSettings={videoSettings} {...rest} />
  ) : (
    <MediaImage {...rest} />
  );
};
export default Media;
