import React from "react";

const SvgExternalArrow = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.43272 0.320312V1.44596H8.74805L8.74804 8.7627L9.8737 8.7627V1.44596H9.87512V0.320313L1.43272 0.320312Z"
        fill="white"
      />
      <path
        d="M0.742188 8.65951L8.70175 0.699943L9.49771 1.4959L1.53814 9.45546L0.742188 8.65951Z"
        fill="white"
      />
    </svg>
  );
};

export default SvgExternalArrow;
