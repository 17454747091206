import React, { createContext, useEffect, useState } from "react";
export const UserPreferences = createContext();

export const UserPreferencesProvider = ({ children }) => {
  const preferenceDetailsState = useState();
  const [verifyStatus, setVerifyStatus] = useState()
  const contextValues = { preferenceDetailsState, verifyStatus, setVerifyStatus };

  return (
    <UserPreferences.Provider value={contextValues}>
      {children}
    </UserPreferences.Provider>
  );
};
